$('#cards-img li').first().removeClass('hidden');

const titleItems = document.querySelectorAll('#cards-title li');
const titleItemsInverted = document.querySelectorAll('#cards-title-inverted li');
const imgItems = document.querySelectorAll('#cards-img li');
const imgItemsInverted = document.querySelectorAll('#cards-img-inverted li');

const itemToggler = (evt, item) => {
    for (let i = 0; i < titleItems.length; i++) {
        evt[i].addEventListener('mouseover', () => {

            if (item[i].classList.contains('hidden')) {

                item.forEach(el => el.style.opacity = '0');

                setTimeout(() => {
                    item.forEach(el => el.classList.add('hidden'));
                    item[i].classList.remove('hidden');
                    item[i].style.opacity = '1';
                }, 100);
            }
        });
    }
}

itemToggler(titleItems, imgItems);
itemToggler(titleItemsInverted, imgItemsInverted);